<template>
  <div id="project-search-conditions">
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-2">
            <v-text-field
              v-model="searchConditions.keyword"
              label="プロジェクト/顧客名/屋号名"
              prefix=""
              outlined
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col align="center" md="4" sm="6" cols="12" class="py-2">
            <v-select
              v-model="searchConditions.industry"
              :items="MasterData.industries"
              item-text="industry"
              item-value="industry"
              label="業界"
              outlined
              clearable
              hide-details
              dense
            >
            </v-select>
          </v-col>
          <v-col align="center" md="4" sm="6" cols="12" class="py-2">
            <v-select
              v-model="searchConditions.businessPhase"
              :items="MasterData.businessPhases"
              item-text="businessPhase"
              item-value="businessPhase"
              label="事業フェーズ"
              outlined
              clearable
              hide-details
              dense
            >
            </v-select>
          </v-col>
          <v-col md="4" sm="6" cols="12" class="py-2">
            <v-select
              v-model="searchConditions.statuses"
              :items="MasterData.statuses"
              item-text="status"
              item-value="id"
              label="ステータス"
              outlined
              multiple
              small-chips
              deletable-chips
              hide-details
              clearable
              dense
            ></v-select>
          </v-col>
          <v-col md="12" sm="6" cols="12" class="py-2">
            <v-select
              v-model="searchConditions.issues"
              :items="MasterData.issues"
              item-text="issue"
              item-value="issue"
              label="課題分類"
              outlined
              multiple
              small-chips
              deletable-chips
              hide-details
              clearable
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="9" align-self="center" class="pr-sm-0 pr-md-3 py-2">
            <v-combobox
              v-model="affiliatedUserName"
              label="PJ関係者"
              placeholder=""
              outlined
              multiple
              small-chips
              deletable-chips
              hide-details
              readonly
              clearable
              dense
              class="affiliated-user"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="2" align-self="center" class="pl-sm-3 pl-md-3 py-2 d-flex justify-center">
            <v-btn
              @click="openUserSelectDialog()"
              color="#547cbc"
              class="white--text"
              block
              large
              rounded
              depressed
              >選択</v-btn
            >
          </v-col>
          <!-- 登録日 -->
          <v-col lg="9" sm="11" cols="12" class="py-2">
            <v-row>
              <v-col cols="2" class="d-flex">
                <div class="text-body-1 align-self-center mx-auto">登録日</div>
              </v-col>
              <v-col>
                <v-menu
                  v-model="dPickerCreatedAtFrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchConditions.createdAt_from"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      readonly
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="searchConditions.createdAt_from"
                    @input="dPickerCreatedAtFrom = false"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-center"><div>〜</div></v-col>
              <v-col>
                <v-menu
                  v-model="dPickerCreatedAtTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchConditions.createdAt_to"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      readonly
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="searchConditions.createdAt_to"
                    @input="dPickerCreatedAtTo = false"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <!-- 更新日 -->
          <v-col lg="9" sm="11" cols="12" class="py-2">
            <v-row>
              <v-col cols="2" class="d-flex">
                <div class="text-body-1 align-self-center mx-auto">更新日</div>
              </v-col>
              <v-col>
                <v-menu
                  v-model="dPickerUpdatedAtFrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchConditions.updatedAt_from"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      readonly
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="searchConditions.updatedAt_from"
                    @input="dPickerUpdatedAtFrom = false"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-center"><div>〜</div></v-col>
              <v-col>
                <v-menu
                  v-model="dPickerUpdatedAtTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchConditions.updatedAt_to"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      readonly
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="searchConditions.updatedAt_to"
                    @input="dPickerUpdatedAtTo = false"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- アーカイブプロジェクト含むかどうか -->
          <v-col cols="12" class="py-2">
            <v-checkbox
              v-model="searchConditions.isArchived"
              label="アーカイブされたプロジェクトを含める"
              color="btPrimary"
              hide-details
            />
          </v-col>
        </v-row>
        
        <!-- パーツ条件 -->
        <div class="my-5">
          <v-card outlined>
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  戦略パーツ絞り込み
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="sections" class="d-flex flex-wrap">
                    <v-checkbox
                      v-for="(item, i) in sections.sections"
                      :key="i"
                      v-model="searchConditions.sections"
                      :label="item.label"
                      :value="item.sectionId"
                      color="btPrimary"
                      hide-details
                      class="me-4"
                    ></v-checkbox>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
        <v-row>
          <v-col></v-col>
          <v-col align="center">
            <v-btn
              @click="$emit('runSearch', searchConditions )"
              color="#547cbc"
              class="white--text"
              block
              rounded
              x-large
              depressed
              >検索</v-btn
            >
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>

      <user-select-dialog
        v-model="userSelectDialog"
        :affiliatedUsers="affiliatedUser"
        @selected="setSelectedUser($event)"
        @canceled="closeUserSelectDialog()"
      >
      </user-select-dialog>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {RepositoryFactory} from '../../../api/RepositoryFactory'

import UserSelectDialog from "../organisms/UserSelectDialog.vue";
import MasterData from '../../../utils/MasterData'

export default {
  props: ["value"],
  components: {
    UserSelectDialog,
  },
  data() {
    return {
      searchConditionsTemplate: {
        keyword: null,
        projectName: null,
        industry: null,
        createdAt_from: null,
        createdAt_to: null,
        updatedAt_from: null,
        updatedAt_to: null,
        businessPhase: null,
        issues: [],
        affiliatedUsers: [],
        statuses: [],
        sections: [],
        isArchived: false,
      },
      dPickerCreatedAtFrom: false,
      dPickerCreatedAtTo: false,
      dPickerUpdatedAtFrom: false,
      dPickerUpdatedAtTo: false,
      userSelectDialog: false,
      affiliatedUser: [],
      MasterData: {},

      affiliatedUserItems: [],
    };
  },
  created() {
    if (this.value) {
      this.$store.commit('searchConditions', this.value);
    } else {
      this.$store.commit('searchConditions', this.searchConditionsTemplate)
    }
  },
  computed: {
    ...mapGetters(["searchConditions", "sections"]),
    affiliatedUserName: {
      get() {
        let nameList = [];
        this.affiliatedUser.forEach((e) => nameList.push(e.userName));
        return nameList;
      },
      set( value ) {
        this.affiliatedUser = value;
      }
    },
  },
  methods: {
    ...mapActions(["getAllSection"]),

    openUserSelectDialog: function () {
      this.userSelectDialog = true;
    },
    closeUserSelectDialog: function () {
      this.userSelectDialog = false;
    },
    setSelectedUser: function (val) {
      this.affiliatedUser = val;
      val.forEach((e) => this.searchConditions.affiliatedUsers.push(e.userId));
      this.closeUserSelectDialog();
    },
    
    async setAffiliatedUsersCmbItems() {
      const UserRepository = RepositoryFactory.get( 'users' );
      const response = await UserRepository.getUserList( {} );
      if (response.data.result && response.data.resultCode === '0000') {
        this.affiliatedUserItems = response.data.data.users;
      } else {
        this._sendError( response.data.message );
      }
    }
  },
  watch: {
    value(val) {
      this.searchConditions = val;

      //PJ関係者の名前を取得
      this.affiliatedUser.forEach((e) =>
        this.affiliatedUserName.push(e.userName)
      );
    },
    affiliatedUser: function( value, oldValue ) {
      if ( value.length == 0 ) {
        this.searchConditions.affiliatedUsers = [];
      }
    },
  },
  async mounted() {
    this.MasterData = MasterData

    // セクション（戦略パーツ）一覧を取得
    const params = {}
    await this.getAllSection(params);

    // PJ関係者コンボボックスの選択肢を取得する
    this.setAffiliatedUsersCmbItems()
  }
};
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
#project-search-conditions {
  .affiliated-user {
    .v-input__append-inner{
      display: none !important;
    }
  }
}
</style>
